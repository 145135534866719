.menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -3.255vw;
  margin-bottom: -3.255vw;
  overflow: hidden;

  @include mobile {
    display: block;
    margin-right: 0;
    margin-bottom: -23px;
  }

  > * {
    margin-right: 3.2552vw;
    margin-bottom: 3.2552vw;
    width: calc((100% - 3.2552vw * 2) / 2);

    &.menu-list__movie {
      width: 100%;
    }

    @include mobile {
      margin-right: 0;
      margin-bottom: 23px;
      width: auto;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.72em;
    font-size: 1.6667vw;
    letter-spacing: 0.15em;
    font-weight: bold;
    @include stripe;
    background-color: $light-gray;
    height: 100%;
    border-radius: 2.0834vw;

    @include mobile {
      font-size: 1.6rem;
      border-radius: 2rem;
    }
  }

  > .menu-list__second {
    width: 100%;
  }

  .menu-list {
    @include mobile {
      display: flex;
      margin-right: -31px;
    }

    > * {
      width: calc((100% - 3.2552vw * 4) / 4);

      @include mobile {
        width: calc((100% - 31px * 2) / 2);
        margin-right: 31px;
      }
    }

    .menu-list__link {
      background-color: $ice-blue;
    }
  }
}

.cover {
  position: relative;

  &__title {
    font-size: 1.6667vw; // 32px
    letter-spacing: 0.15em;
    font-weight: bold;
    padding: 0.5em 1em;
    min-height: 7.3438vw;
    min-width: 14.6354vw;
    background-color: $ice-blue;
    @include stripe;
    border-radius: 2.0834vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    opacity: 0;

    @include mobile {
      font-size: 1.6rem;
      min-height: 70px;
      min-width: 140px;
      transform: translate(-50%, -67%);
      border-radius: 20px;
    }
  }

  &__img {
    overflow: hidden;

    img {
      opacity: 0;
      transform: scale(1.15);
    }
  }
}

.article {
  &__title {
    font-size: 1.875vw;
    font-weight: bold;
    line-height: 1.3333;

    @include mobile {
      font-size: 1.8rem;
    }
  }

  &__head {
    margin-bottom: 0.5208vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      margin-bottom: 5px;
    }
  }

  &__text + .article__img {
    margin-top: 2.6042vw;

    @include mobile {
      margin-top: 8px;
    }
  }

  &__text + .defi-list {
    margin-top: 0.7813vw;

    @include mobile {
      margin-top: 10px;
    }
  }

  .article {
    .article {
      &__title {
        font-size: 1.4583vw;

        @include mobile {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.more {
  @include stripe;
  font-weight: bold;
  font-size: 1.5625vw;
  padding: 0 0.7em;
  line-height: 1.2;

  @include mobile {
    font-size: 1.5rem;
  }
}

.defi-list {
  .defi-list__item + .defi-list__item {
    margin-top: 0.7813vw;

    @include mobile {
      margin-top: 10px;
    }
  }

  &__term {
    margin-bottom: 0.5208vw;
    font-size: 1.4583vw; // 28px
    font-weight: bold;
    line-height: 1.2;

    @include mobile {
      font-size: 1.4rem;
      margin-bottom: 5px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;

  &__item {
    width: 14.6354vw;

    @include mobile {
      width: 141px;
    }
  }

  .pagination__item + .pagination__item {
    margin-left: 2.4479vw;

    @include mobile {
      margin-left: 23px;
    }
  }
}

.bg-text {
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 32.552vw;
  max-width: 29.2969vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0.72em;
  font-size: 1.6667vw;
  font-weight: bold;
  height: 100%;
  border-radius: 2.0834vw;

  @include mobile {
    font-size: 1.5rem;
    border-radius: 2rem;
    max-width: 281px;
    padding: 0.8em;
  }
}

.box {
  &__title {
    font-size: 1.875vw;
    font-weight: bold;
    text-align: center;
    padding: 0.45em 1em;

    @include mobile {
      font-size: 1.8rem;
    }
  }

  &__desc {
    padding: 2.4479vw;
    border-style: solid;
    border-width: 2px;
    border-top: none;

    @include mobile {
      padding: 23px;
      border-width: 1px;
    }

    &_red {
      border-color: $red;
    }

    &_ice-blue {
      border-color: $ice-blue;
    }

    &_green {
      border-color: $green;
    }

    &_yellow {
      border-color: $yellow;
    }
  }
}

.iframe-wrap {
  padding-top: 56.25%;
  position: relative;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
