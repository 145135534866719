.site-footer {
  .footer-link {
    .menu-list {
      + .menu-list {
        margin-top: 3.2552vw;

        @include mobile {
          margin-top: 23px;
        }
      }
    }
  }

  .sns-list {
    width: 48.9583vw;
    margin-top: 7.4479vw;
    margin-right: auto;
    margin-bottom: 7.4479vw;
    margin-left: auto;
    display: flex;
    justify-content: space-between;

    @include mobile {
      width: auto;
      margin-top: 58px;
      margin-bottom: 58px;
    }

    &__item {
      img {
        height: 4.8958vw;
        width: auto;

        @include mobile {
          height: 47px;
        }
      }
    }
  }

  .copyright {
    text-align: center;
    padding: 0.7em 25px;
    font-size: 1.0417vw;
    letter-spacing: 0.075em;
    line-height: 1.6;

    small {
      font-size: inherit;
    }

    @include mobile {
      font-size: 1rem;
      padding: 0.8em 25px;
    }
  }
}
