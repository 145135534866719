// @import "helper/row";

.inline-block {
  font-size: 0;
  li {
    display: inline-block;
  }
}

a {
  @include link-color($base-font-color, lighten($base-font-color, 0%));
  text-decoration: none;
  outline: none;
  .alpha {
    opacity: 1;
    transition: all 100ms $ease-in-out;
    @include mobile {
      transition-duration: 0s;
    }
  }
  &:hover .alpha {
    opacity: $link-alpha;
    @include mobile {
      opacity: 1;
    }
  }
}

.text-center {
  text-align: center;
}

.figure,
.flex {
  img {
    width: 100%;
  }
}

.tag {
  height: 0;
  overflow: hidden;
}

img {
  &.flex {
    width: 100%;
    height: auto;
  }
  &.full {
    // width: auto;
    max-width: none;
  }

  &.cover {
    object-fit: cover;
  }
}

.show-small {
  display: none;
  @include mobile {
    display: inherit;
  }
}
.hide-small {
  @include mobile {
    display: none;
  }
}
.small-nopad {
  @include mobile {
    padding: 0 !important;
  }
}

.container {
  padding-right: 15.8333vw;
  padding-left: 15.8333vw;

  @include mobile {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.strong {
  color: $navy;
  font-weight: bold;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-ice-blue {
  background-color: $ice-blue !important;
}

.bg-green {
  background-color: $green !important;
}

.bg-yellow {
  background-color: $yellow !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
  color: #fff;
}

.bg-red {
  background-color: $red !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-gray {
  background-color: #ccc !important;
}

.mark-ice-blue {
  background: linear-gradient(
    transparent 70%,
    $ice-blue 70%,
    $ice-blue 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-orange {
  background: linear-gradient(
    transparent 70%,
    $orange 70%,
    $orange 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-yellow {
  background: linear-gradient(
    transparent 70%,
    $yellow 70%,
    $yellow 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-green {
  background: linear-gradient(
    transparent 70%,
    $green 70%,
    $green 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-purple {
  background: linear-gradient(
    transparent 70%,
    $purple 70%,
    $purple 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-red {
  background: linear-gradient(
    transparent 70%,
    $red 70%,
    $red 90%,
    transparent 90%,
    transparent 100%
  );
}

.mark-gray {
  background: linear-gradient(
    transparent 70%,
    #ccc 70%,
    #ccc 90%,
    transparent 90%,
    transparent 100%
  );
}

.stripe {
  @include stripe;
}
