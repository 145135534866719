.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;

  .globalnav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;

    &.is-show {
      opacity: 1;
      visibility: inherit;
    }

    &__content {
      position: relative;
    }

    &__bg {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
      }

      picture {
        img {
          width: 100%;
          min-height: 100vh;
          object-fit: cover;
        }
      }
    }

    &__inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48.9583vw;

      @include mobile {
        width: 100%;
        padding: 0 30px;
      }

      * {
        color: #fff;
      }
    }

    &__main {
      display: flex;
      align-items: end;
      justify-content: space-between;

      @include mobile {
        padding-left: 16px;
      }
    }

    &__sns {
      margin-top: 6.25vw;

      @include mobile {
        margin-top: 32px;
      }
    }
  }

  .gnav-list {
    font-size: 2.0833vw;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 1.2;

    @include mobile {
      font-size: 2rem;
    }

    .gnav-list__item:not(:last-of-type) {
      margin-bottom: 1.5625vw;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    &__link {
      display: inline-block;
      padding: 0.25em 0;
    }

    &__second {
      font-size: 1.6667vw;
      padding-left: 1.5em;

      @include mobile {
        font-size: 1.6rem;
      }
    }

    &__second-item {
      margin-top: 1.5625vw;

      @include mobile {
        margin-top: 7px;
      }
    }

    &__second-link {
      display: inline-block;
      padding: 0.25em 0;
    }
  }

  .salon-link {
    background-color: #fff;
    color: $dark-gray;
    font-size: 1.875vw; // 3.6rem
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9em 0.25em;
    border-radius: 1.1em;
    font-weight: bold;
    letter-spacing: 0.2em;
    min-width: 7.2917vw;
    writing-mode: vertical-lr;
    text-align: center;

    @include mobile {
      font-size: 1.8rem;
      padding: 0.9em 0.25em;
      min-width: 70px;
    }

    + .salon-link {
      margin-top: 2.6042vw;

      @include mobile {
        margin-top: 23px;
      }
    }
  }

  .globalnav-others {
    .salon-link:first-of-type {
      padding: 1.6em 0.25em;
    }
  }

  .sns-list {
    display: flex;
    justify-content: space-between;

    &__item {
      img {
        height: 4.8958vw;
        width: auto;

        @include mobile {
          height: 47px;
        }
      }
    }
  }

  .hamburger-btn {
    position: absolute;
    top: 10vw;
    right: 10vw;
    z-index: 12;
    width: 10vw;
    height: 7.2917vw;
    cursor: pointer;

    @include mobile {
      top: 30px;
      right: 23px;
      width: 47px;
      height: 34px;
    }

    &__line {
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.4948vw;
      background-color: $dark-gray;
      transition: all 0.4s;
      z-index: 1;

      @include mobile {
        height: 2px;
      }
    }
    @for $i from 1 through 6 {
      .hamburger-btn__line:nth-of-type(#{$i}) {
        top: calc((100% - 0.4948vw) / 5 * #{$i - 1});

        @include mobile {
          top: calc((100% - 2px) / 5 * #{$i - 1});
        }
      }
    }

    &.is-open {
      .hamburger-btn__line {
        background-color: #fff;

        &:first-of-type {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }

        &:last-of-type {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          opacity: 0;
        }
      }
    }
  }
}
