*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  font-size: $global-font-size;
  -webkit-font-smoothing: antialiased;
}

/*
  タイポグラフィの微調整
  4. アクセシブルなline-heightを追加
  5. テキストのレンダリングを改善
*/
body {
  -webkit-font-smoothing: antialiased;
  font-size: $base-font-size;
  font-family: $font__noto;
  letter-spacing: 0.05em;
  font-feature-settings: 'palt';
  text-rendering: optimizeLegibility;
  line-height: $base-line-height;
  color: $base-font-color;

  @include mobile {
    font-size: $base-font-size-small;
  }

  &.is-fixed {
    overflow: hidden;
  }
}

main,
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/*
  6. メディア要素のデフォルトを改善
*/
// img, picture, video, canvas, svg {
//   display: block;
//   max-width: 100%;
// }

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-style: normal;
}

p {
  text-align: justify;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  vertical-align: bottom;
  border-style: none;
  // max-width: 100%;
  width: 100%;
  height: auto;
}

/*
  form用resetCSS
*/

input[type='text'],
input[type='tel'],
input[type='email'] {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}
input[type='radio'],
input[type='checkbox'] {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  border-radius: 0;
}

button,
input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  color: inherit;
}

a {
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
}

mark {
  color: inherit;
}
