@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://use.typekit.net/cik1cfv.css');

$global-font-size: 62.5%;

$base-font-size: max(1.25vw, 1.2rem); // 24px
$base-font-size-small: 1.2rem;

$base-font-color: #4c4c4c;

$base-line-height: 1.5;

$font__gothic: 'ヒラギノ角ゴ Pro W3', Hiragino Kaku Gothic Pro, Osaka,
  'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', MS P Gothic, Verdana, sans-serif;

$font__apple_gothic: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font__noto: 'Noto Sans JP', '游ゴシック', YuGothic, 'メイリオ', Meiryo,
  'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, Verdana, sans-serif;

$font__yugothic: '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック',
  'MS PGothic', Helvetica, Arial, Verdana, sans-serif;

$font__mincho: 'Noto Serif JP', '游明朝', YuMincho, 'Hiragino Mincho ProN W3',
  'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝',
  'ＭＳ 明朝', serif;

$font__din: 'din-2014', 'Noto Sans JP', '游ゴシック', YuGothic, 'メイリオ',
  Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, Verdana, sans-serif;

// Extend
// CSS Transition Easing
// Default
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
// In
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$gnav__list: top event philosophy service works recruit company;

$link-alpha: 0.8;

$stripe_lg: repeating-linear-gradient(
  -45deg,
  transparent,
  transparent 1.4583vw,
  rgba(255, 255, 255, 1) 1.4583vw,
  rgba(255, 255, 255, 1) 1.5625vw
);
$stripe_pc: repeating-linear-gradient(
  -45deg,
  transparent,
  transparent 0.7292vw,
  rgba(255, 255, 255, 1) 0.7292vw,
  rgba(255, 255, 255, 1) 0.78125vw
);
$stripe_sp: repeating-linear-gradient(
  -45deg,
  transparent,
  transparent 7px,
  rgba(255, 255, 255, 1) 7px,
  rgba(255, 255, 255, 1) 7.5px
);

$light-gray: #e5e5e5;
$dark-gray: #4c4c4c;
$blue: #61bed5;
$ice-blue: #b5dfdf;
$green: #a1d2a3;
$yellow: #f4db92;
$orange: #f4ac85;
$navy: #6b7bb8;
$red: #e99999;
$purple: #d7a7cb;
