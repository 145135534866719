.section-hero2 {
  .headline {
    @include stripe_hero;
    padding: 10vw;
    text-align: center;

    @include mobile {
      padding: 47px 25px;
    }

    &__title {
      display: inline-block;
      min-width: 39.0625vw;
      margin: 0 auto;
      font-size: 4.1667vw; // 80px
      font-weight: bold;
      letter-spacing: 0.15em;
      line-height: 1.7;
      position: relative;

      @include mobile {
        font-size: 2rem;
        line-height: 2.3;
        min-width: 188px;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: currentColor;

        @include mobile {
          height: 0.5px;
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }
}
